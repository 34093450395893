define(["handlebars"], function(Handlebars) { return Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, functionType="function", escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  
  return " disable";
  }

function program3(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n        <a class=\"text\" id=\"hrefPrev\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.pageControl)),stack1 == null || stack1 === false ? stack1 : stack1.previousText)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</a>\n    ";
  return buffer;
  }

function program5(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n    <li class=\"n-unit pagination-nav-page-number";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.selected), {hash:{},inverse:self.noop,fn:self.program(6, program6, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\">\n        <a href=\"?pageNumber="
    + escapeExpression(((stack1 = (depth0 && depth0.value)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "\">"
    + escapeExpression(((stack1 = (depth0 && depth0.value)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</a>\n    </li>\n";
  return buffer;
  }
function program6(depth0,data) {
  
  
  return " currentpage";
  }

function program8(depth0,data) {
  
  var buffer = "", stack1;
  buffer += "\n        <a class=\"text\" id=\"hrefNext\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.pageControl)),stack1 == null || stack1 === false ? stack1 : stack1.nextText)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + "</a>\n    ";
  return buffer;
  }

  buffer += "<li class=\"n-unit previous";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.pageControl)),stack1 == null || stack1 === false ? stack1 : stack1.previous), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" data-facetcontrol=\"link-previous\" id=\"hrefPrevious\">\n    ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.pageControl)),stack1 == null || stack1 === false ? stack1 : stack1.previous), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</li>\n\n";
  stack1 = helpers.each.call(depth0, (depth0 && depth0.pageObject), {hash:{},inverse:self.noop,fn:self.program(5, program5, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n\n<li class=\"n-unit next";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.pageControl)),stack1 == null || stack1 === false ? stack1 : stack1.next), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" data-facetcontrol=\"link-next\" id=\"hrefNext\">\n    ";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 && depth0.pageControl)),stack1 == null || stack1 === false ? stack1 : stack1.next), {hash:{},inverse:self.noop,fn:self.program(8, program8, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n</li>\n";
  return buffer;
  }); });